import React, { Suspense, lazy } from 'react'

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import './index.css';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <LazyApp />
    </Suspense>
  </BrowserRouter>

);

